(function (angular, _, undefined) {
    "use strict";
    var resume = angular.module("mikesResume");

    resume.factory("ResumeService", ["$http", "$q", "$localStorage",
        function ($http, $q, $localStorage) {
        return {
            "getResume": function () {
                var def = $q.defer(),
                    after = "";

                if (typeof $localStorage.resume !== "object") {
                    $localStorage.resume = {};
                }

                if (_.has($localStorage.resume, "updated")) {
                    after = "?after=" + (new Date($localStorage.resume.updated)).getTime();
                }

                $http.get("/api/resume")
                    .then(function (res) {
                        $localStorage.resume = res.data;
                        def.resolve();
                    },
                    function (err) {
                        def.reject(err);
                    });
                return def.promise;
            }
        };
    }]);

})(angular, _);
